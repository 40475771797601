#visitType{
    width: 100%;
    height: 30px;
    border-radius: 5px;
    opacity: 0.4;
    padding: 3px 0px 3px 5px;
    font-size: 15px;
}

#errorPrint{
    color: red;
}

#fnameError{
    color: red;
}

#lnameError{
    color: red;
}

#emailError{
    color: red;
}

#form Input{
    box-shadow: none;
}