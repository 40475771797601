.navs li{
    padding-left: 50px;
   
   
}

.navs{
    margin-left:-60px;
}

.edu{
    display: none;
}


#n1{
    cursor: pointer;
    font-weight: 600;
}

.contain{
    position: relative;
    /* width: 50%; */
}
  
.onImage{
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    /* top: 100%; */
    left: 50%;
    margin-top: 30px;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .contain:hover #dpImg {
    opacity: 0.3;
  }
  
  .contain:hover .onImage {
    opacity: 1;
  }
  
  



#file{
  display: none;
}

#uploadBtn{
  margin-top: 10px;
  cursor: pointer;
}
  

#goLeft{
  margin-left: -580px;
}

#godegreeLeft{
  margin-left: -260px;
}

#gocityLeft{
  margin-left: -270px;
}
#gocountryLeft{
  margin-left: -250px;
}

#gobioLeft{
  margin-left: -460px;
}

#goemailLeft{
  margin-right: 540px;
}

#gonameLeft{
  margin-right: 230px;
}

#gofieldLeft{
  margin-right: 210px;
}

#gostartLeft{
  margin-right: 570px;
}

#goendLeft{
  margin-right: 480px;
}

/* #tab6{
  background-color: aqua;
  height: 300px;
} */


#addresses Input{
  border: 1px solid black;
}
#a{
  border: 1px solid black;
}


