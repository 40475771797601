


#sideBar{
    position: fixed;
    left: 0;
    top: 0;
   
    width: 50px;
    z-index: 99;
    /* padding: 6px 14px; */
   
    transition: all 0.5s ease;
    overflow-x :hidden;
    
}
  #sideBar .open{
    width: 250px;
    transition: all 0.5s ease;
  }
  
  #sideBar.open .logo-details .icon,
  #sideBar.open .logo-details .logo_name{
    opacity: 1;
  }
 
  
 
/*   
  #sideBar input{
    font-size: 15px;
    color: #FFF;
    font-weight: 400;
    outline: none;
    height: 50px;
    width: 100%;
    width: 50px;
    border: none;
    border-radius: 12px;
    transition: all 0.5s ease;
    
  }
  #sideBar.open input{
    padding: 0 20px 0 50px;
    width: 100%;
  }
  #sideBar .bx-search{
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    font-size: 22px;
  
    color: #FFF;
  }
  #sideBar.open .bx-search:hover{
  
    color: #FFF;
  }
  #sideBar .bx-search:hover{
    background: #FFF;
    color: #11101d;
  }
  #sideBar li a{
    display: flex;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    align-items: center;
    text-decoration: none;
    transition: all 0.4s ease;
   
  }
  #sideBar li a:hover{
    background: #FFF;
  }
  #sideBar li a .links_name{
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
    opacity: 0;
    pointer-events: none;
    transition: 0.4s;
  }
  #sideBar.open li a .links_name{
    opacity: 1;
    pointer-events: auto;
  }
  #sideBar li a:hover .links_name,
  #sideBar li a:hover i{
    transition: all 0.5s ease;
    color: #11101D;
  }
  #sideBar li i{
    height: 50px;
    line-height: 50px;
    font-size: 18px;
    border-radius: 12px;
  }
  */
  
 

#samvLogo{
    display: block;
    margin-left: 10px;
}

/* #btn{
    margin-right: 3000px;
    width: 100%;
    height: 10px;
}

#btn2{
    width:100%;
    height: 30px;
} */

/* .fa-circle-xmark{
    width:100px;
} */

#btn1{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none; 
}

#btn2{
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none; 
}

