Label, legend{
    font-size: 17px;
    
}

/* label{
    font-size: 16px;
} */

#smallLabel{
    font-size: 16px;
}

Form Input{
    box-shadow: none;
}

#buttons{
    display: none;
    transition: 3s;
    cursor: pointer;
}

#displayFields{
    display: none;
}
#displayFields1{
    display: none;
}
#displayFields2{
    display: none;
}

/* #toggles{
    border: 1px solid black;
    padding: 15px;
} */


#form Input{
    box-shadow: none;
}