/* .login{
    overflow-y: hidden;
} */

Label{
    font-weight: 700;
}


#samloginLogo{
    width: 60px;
    height: 60px;
}

#createAccount:hover{
    color: blue;
}